'use client';

import { useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { ApiOnceResponse } from '@/src/modules/common/models/common.models';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { ProjectEntity } from '@/src/modules/events/models/projects.models';
import { projectEventsRoutes } from '@/src/modules/events/routes/events.api.routes';

export type UseProject = {
	project?: ProjectEntity;
	isLoading: boolean;
	reload: UseGETReloadFunction;
	error: unknown;
};

export const useProject = (projectId?: string, canGet = true): UseProject => {
	const [endpoint, setEndpoint] = useState<string>();
	const [project, setProject] = useState<ProjectEntity>();

	const { data, isLoading, reload, error } = useGET<ApiOnceResponse<ProjectEntity>, unknown>({
		endpoint,
		canGet: endpoint !== undefined && canGet,
	});

	useEffect(() => {
		if (projectId) {
			setEndpoint(projectEventsRoutes.projectApiPath(projectId));
		}
	}, [projectId]);

	useEffect(() => {
		if (data?.data) {
			setProject(eventsActions.addRightsToProject(data.data));
		}
	}, [data?.data]);

	return useMemo(
		() => ({
			project,
			isLoading,
			reload,
			error,
		}),
		[
			{
				project,
				isLoading,
				reload,
				error,
			},
		],
	);
};
