'use client';

import { useEffect, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { usePOST } from '@/src/modules/common/hooks/usePOST';
import { ApiOnceResponse } from '@/src/modules/common/models/common.models';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { useProject } from '@/src/modules/events/hooks/useProject';
import { useSeason } from '@/src/modules/events/hooks/useSeason';
import { EventEntity } from '@/src/modules/events/models/events.models';
import { projectEventsRoutes } from '@/src/modules/events/routes/events.api.routes';

export type UseEvent = [
	event: EventEntity | null,
	isLoading: boolean,
	reload: UseGETReloadFunction,
	archiveCommitteeEvent: () => Promise<EventEntity | undefined | null>,
];

export const useEvent = (eventId?: number, canGet = true): UseEvent => {
	const [endpoints, setEndpoints] = useState<
		{ event: string; eventArchive: string } | undefined
	>();

	const [event, setEvent] = useState<EventEntity | null | undefined>(null);
	const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(true);

	const { data, isLoading, reload } = useGET<ApiOnceResponse<EventEntity>, unknown>({
		endpoint: endpoints?.event,
		canGet: endpoints?.event !== undefined && canGet,
	});

	const { season } = useSeason(data?.data?.seasonId);
	const { project } = useProject(data?.data?.projectId);

	const archiveCommitteeEventPost = usePOST<unknown, { data: EventEntity }>({
		endpoint: endpoints?.eventArchive,
	});

	useEffect(() => {
		if (eventId) {
			setEndpoints({
				event: projectEventsRoutes.eventApiPath(eventId),
				eventArchive: projectEventsRoutes.eventArchiveApiPath(eventId),
			});
		}
	}, [eventId]);

	useEffect(() => {
		if (data?.data) {
			setEvent(
				eventsActions.addRightsToEvent(
					eventsActions.transformEvent(data?.data, season, project),
				),
			);
		}
	}, [data?.data, season, project]);

	useEffect(() => {
		setIsLoadingEvent(isLoading);
	}, [isLoading]);

	const archiveCommitteeEvent = async () => {
		setIsLoadingEvent(true);

		const eventResponse = (await archiveCommitteeEventPost())?.data;

		setEvent({ ...eventResponse });

		setIsLoadingEvent(false);

		return eventResponse;
	};

	return [event ?? null, isLoadingEvent, reload, archiveCommitteeEvent];
};
